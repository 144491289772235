<template>
    <section class="custom-scroll overflow-auto pt-3" style="height:calc(100vh - 44px)">
		<div class="col ml-5 mt-3">
                <p class="f-28 text-black f-600 mb-2">
                    Módulos disponibles
                </p>
                <p class="text-general f-14 f-300 mb-4" style="max-width:580px;">
                    selecciona los módulos que desea usar en el sistema
                </p>
        </div>
        <div v-for="(data, index) in modulos" :key="index" class="row mx-0 align-items-center mx-5">
            <el-checkbox v-model="data.texto" class="check-dark" :true-label="1" :false-label="0" @change="editModuloEstado(data)"/>
            <!-- <i class="icon-key f-15 text-black m-2" /> -->
            <i :class="`${data.tipo == 111 ? 'icon-pedidos' : data.tipo == 112 ? 'icon-card-text-outline' : data.tipo == 113 ? 'icon-switch' : '' } f-15 text-black m-2`" />

			 
            <div class="col-auto text-black f-18">
                {{ data.nombre }}
            </div>
        </div>
    </section>
</template>

<script>
import Service from '~/services/proyectos/proyectos'

export default {

    data(){
        return {
            modulos: [],
        }
    },

    mounted(){
        this.getData();
    },

    methods:{

        async getData(){
            try {
                const { data } = await Service.getModulosProyecto(this.$usuario.proyecto);
                this.modulos = data.parametros;
            } catch(e){
                this.error_catch(e)
            }
        },

        async editModuloEstado(item){
			try {
				const payload = {
					tipo : item.tipo
				}
				const { data } = await Service.editModuloProyecto(this.$usuario.proyecto, payload)
		        this.notificacion('Éxito', data.mensaje, 'success')
			} catch (error) {
				this.error_catch(e)
			}
        },
    },
}
</script>

<style lang="scss" scoped>
	.label{
		color: #5F6D84;
		font-size: 12px;
		padding-left: 15px;
	}
	</style>